import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({children}) => {
    const isAuthenticated = localStorage.getItem('token');

    if (!isAuthenticated) {
        return <Navigate to="/auth" replace />;
    }
    return children;
    // return <Outlet />;
}

export default ProtectedRoute;