import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthenticatedLayout from './AuthenticatedLayout';
import { UserProvider } from '../contexts/UserContext';

function RootLayout() {

    return (
        <UserProvider>
            <AuthenticatedLayout>
                <Outlet />
            </AuthenticatedLayout>    
        </UserProvider>
    )
}

export default RootLayout;