import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Layout, Menu, theme } from 'antd';
import axios from 'axios';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { menuOptions, urlEndpoints, appVersion } from '../helpers/constants';
import moment from 'moment';
import { useUser } from '../contexts/UserContext';
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

function AuthenticatedLayout({children}) {
  const { login } = useUser();
  const navigate = useNavigate();
  const [time, setTime] = useState(moment().format('HH:mm:ss'));
  const [headerTitle, setHeaderTitle] = useState("Welcome");
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [appDetails, setAppDetails] = useState(null);
  const [payMethods, setPayMethods] = useState([]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const loggedInUserStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '30vh',
    color: 'grey',
    padding: collapsed ? '0.5rem' : '2rem'
  }

  function handleMenuClick(label) {
    setHeaderTitle(label);
  }

const getPaymentMethodsList = async (token) => {
    try {
      // setIsLoading(true);
        // const token = await localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${token}`
        }
        const resp = await axios.get(`${urlEndpoints.getPaymentMethodsList}`, {headers});
        if (resp.status === 200) {
          const {paymentModes, message} = resp.data;
          // notification.success({
          //   // message: 'Success',
          //   description: message || 'Fetched Payment Methods Successfully!'
          // })
          return [];
          // setPaymentMethods(paymentModes);
        } else {
          return [];
        }
        
    } catch(e) {
        console.log('Error : ', e);
        return [];
        // notification.error({
        //     // message: 'Error',
        //     description: e.response.data || 'Something went wrong!'
        // })
    } 
    // finally {
    //     // setIsLoading(false);
    // }
}

    useEffect(() => {
      const timerId = setInterval(() => {
          setTime(moment().format('HH:mm:ss'));
      }, 1000);

      return () => clearInterval(timerId);
    }, []);

    // useEffect(() => {
    //   const updateMenuOptions = async () => {
    //     const token = await localStorage.getItem('token');
    //     if (token) {
    //       const pms = await getPaymentMethodsList(token);
    //       debugger;
    //       // const updatedMenuOptions = menuOptions
    //       const updatedMenuOptions = menuOptions.map(menuItem => {
    //         if (menuItem.label === 'System Config') {
    //           for (let i=0; i<pms.length; i++) {
    //             if (pms[i].paymentMode === 'UPI') {
    //               menuItem.children.push({
    //                 label: 'Upload UPI QR',
    //                 path: '/system-config/qr-upload',
    //                 component: 'QRdetails'
    //               });
    //             } else if (pms[i].paymentMode === 'NETBANKING') {
    //               menuItem.children.push({
    //                 label: 'Bank Accounts',
    //                 path: 'system-config/bank-accounts',
    //                 component: 'BankAccounts'
    //               });
    //             }
    //           }
    //           // return menuItem;
    //         }
    //         return menuItem;
    //       })
    //       setPayMethods(updatedMenuOptions);
    //     } else {
    //       setPayMethods(menuOptions);
    //     }
    //   }

    //   updateMenuOptions();
    // }, [localStorage.getItem('token')]);

    function renderMenuItems(menuItems) {
        return menuItems.map(option => {
          if (option.children && option.children.length > 0) {
            return (
              <SubMenu key={option.path} icon={option.icon} title={option.label}>
                {/* <LogoutOutlined /> */}
                {renderMenuItems(option.children)}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={option.path} onClick={() => handleMenuClick(option.label)} >
                <NavLink to={option.path} className={({isActive}) => isActive ? 'active' : ''} end style={({isActive}) => ({color: isActive ? 'lightgreen' : undefined })}>{option.label}</NavLink>
              </Menu.Item>
            );
          }
        });
    }

    const fetchAppDetails = async () => {
      try {
        setIsLoading(true);
          const token = await localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${token}`
          }

          const resp = await axios.get(urlEndpoints.fetchAppDetails, {headers});
          if (resp.status === 200) {
            const {app, username, role} = resp.data;
            const {clientName, appName, appId, industry} = app;
            login({
              username,
              role
            });
            setAppDetails({
              username,
              appName,
              appId,
              role
            });
          }
      } catch(e) {
          console.log('Error : ', e);
      } finally {
          setIsLoading(false);
      }
    }

    useEffect(() => {
      fetchAppDetails();
    }, []);

    return (
        <>
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{minHeight: '100vh'}}>
                <div className="demo-logo-vertical" style={loggedInUserStyle}>
                <div style={{color:'white', fontSize: '1.4rem'}}>Admin</div>
                {/* <div>Img</div> */}
                {/* <hr /> */}
                <div style={{color:'white', fontSize: '0.8rem'}}>{moment().format('MMM Do, YYYY')}</div>
                <div style={{color:'white', fontSize: '0.8rem'}}>{time}</div>
                <div>
                  {isLoading 
                    ? <div>
                        Loading ... 
                      </div> 
                    : appDetails ? (<div style={{margin: '1rem 0'}}>
                        <div style={{color: '#FF6484'}}>{appDetails.username} {!collapsed && <div style={{color: '#36a2eb'}}>{appDetails.appName} ({appDetails.appId})</div>}</div>
                        <div style={{fontSize: '0.8rem', color: '#4bc0c0'}}>{appDetails.role}</div>
                      </div>) : null}
                </div>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                >
                    {renderMenuItems(menuOptions)}
                </Menu>
                <div style={{color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5rem', marginBottom: '1rem'}}>
                {collapsed ? appVersion.no : appVersion.full}
                </div>
            </Sider>
            <Layout>
                <Header
                style={{
                    padding: 0,
                    background: colorBgContainer,
                }}
                >
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  {/* <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                    }}
                  /> */}
                  <div style={{fontSize: '20px', flex: 1, textAlign: 'center', fontSize: '1.6rem'}}>
                    <b>{headerTitle}</b>
                  </div>
                  <Button
                    style={{marginRight: '1.5rem'}}
                    icon={<LogoutOutlined />}
                    onClick={() => navigate('/logout')}
                  />
                </div>
                </Header>
                <Content
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
                >
                    {children}
                </Content>
            </Layout>
            </Layout>
        </>
    )
}

export default AuthenticatedLayout;