import {
  AccountBookOutlined,
  DashboardOutlined,
  PieChartOutlined,
  TeamOutlined,
  CustomerServiceOutlined,
  IdcardOutlined,
  DollarOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  MessageOutlined
} from '@ant-design/icons';

const hostname = process.env.REACT_APP_HOSTNAME;
const version = process.env.REACT_APP_VERSION;

export const urlEndpoints = {
  registerAdminUser: `${hostname}${version}admin/register`,
  login: `${hostname}${version}admin/login`,
  getUserList: `${hostname}${version}users`,
  updateUserStatus: `${hostname}${version}users/{userId}/status`,
  getServiceList: `${hostname}${version}services`,
  createService: `${hostname}${version}services`,
  updateServiceStatus: `${hostname}${version}services/{serviceId}/status`,
  getProviderList: `${hostname}${version}providers`,
  createProvider: `${hostname}${version}providers`,
  updateProviderStatus: `${hostname}${version}providers/{providerId}/status`,
  getPayments: `${hostname}${version}payments?`,
  getWalletTransactions: `${hostname}${version}payments/wallet-transactions`,
  getGatewaysList: `${hostname}${version}gateways`,
  getSuccessStats: `${hostname}${version}stats/success`,
  getUserStats: `${hostname}${version}stats/user-stats`,
  getServiceStats: `${hostname}${version}stats/service-stats`,
  getPaymentStats: `${hostname}${version}stats/payment-stats`,
  getReferralStats: `${hostname}${version}stats/referral-stats`,
  getDailyStats: `${hostname}${version}stats/daily-stats`,
  getSMSlist: `${hostname}${version}sms`,
  getContentReport: `${hostname}${version}contents`,
  getBankTransactionsList: `${hostname}${version}bank-transactions`,
  getAllSettings: `${hostname}${version}settings`,
  getPaymentsReport: `${hostname}${version}payments`,
  getProviderServiceReport: `${hostname}${version}provider-service`,
  getRequestReport: `${hostname}${version}requests`,
  editSetting: `${hostname}${version}settings/{settingId}`,
  getSMSforRequestId: `${hostname}${version}requests/{requestId}/sms`,
  editServiceProvider: `${hostname}${version}provider-service/{requestId}`,
  updateServiceProviderStatus: `${hostname}${version}providers/{requestId}/status`,
  getDadhboardOverview: `${hostname}${version}stats/home-stats`,
  createGateway: `${hostname}${version}gateways`,
  createSetting: `${hostname}${version}settings`,
  updateReferralStatus: `${hostname}${version}referral/status`,
  userDetails: `${hostname}${version}users/balance`,
  addBalance: `${hostname}${version}payments/add-balance`,
  editProvider: `${hostname}${version}providers/{providerId}`,
  updateContent: `${hostname}${version}contents/{contentId}`,
  createProviderService: `${hostname}${version}provider-service`,
  createProviderServiceJson: `${hostname}${version}provider-service/bulk`,
  updateProviderServiceStatus: `${hostname}${version}provider-service/{providerServiceId}/status`,
  getProviderBalance: `${hostname}${version}providers`,
  bulkProgress: `${hostname}${version}provider-service/bulk-progress`,
  sendNotification: `${hostname}${version}notifications/sendNotification`,
  broadcastNotification: `${hostname}${version}notifications/broadcastNotification`,
  searchUsers: `${hostname}${version}notifications/search-users`,
}

const iconStyle = {
  fontSize: '1.4rem'
}

const applicationVersion = '0.0.1';

export const appVersion = {
  full: `Version ${applicationVersion}`,
  no: applicationVersion
}

export const menuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    key: 'dashboard',
    icon: <DashboardOutlined style={iconStyle} />,
    component: "Dashboard",
    children: [
      {
        label: 'OverView',
        path: '/dashboard',
        key: 'dashboard_overview',
        component: "Dashboard",
      }
    ]
  },
  {
    label: 'Reports',
    path: '/reports',
    key: 'reports',
    icon: <PieChartOutlined style={iconStyle} />,
    children: [
      {
        label: 'User Report',
        path: '/reports/users',
        component: 'Users'
      },
      {
        label: 'Request Report',
        path: '/reports/request',
        component: 'RequestReport'
      },
      {
        label: 'Service Report',
        path: '/reports/services',
        component: 'Services'
      },
      {
        label: 'SMS Report',
        path: '/reports/sms',
        component: 'SMS'
      },
      {
        label: 'Provider Report',
        path: '/reports/providers',
        component: 'Providers'
      },
      {
        label: 'Gateway Report',
        path: '/reports/gateway',
        component: 'Gateways'
      },
      {
        label: 'Provider Service Report',
        path: '/reports/provider-service',
        component: 'ProviderServiceReport'
      },
      {
        label: 'Payment Report',
        path: '/reports/payment',
        component: 'Payments'
      },
      {
        label: 'Content Report',
        path: '/reports/content',
        component: 'ContentReport'
      },
      {
        label: 'API User Report',
        path: '/reports/api-user',
        component: 'Providers'
      },
      {
        label: 'Bank Txn Report',
        path: '/reports/bank-txn',
        component: 'BankTransactions'
      },
    ]
  },
  {
    label: 'Stats',
    path: '/stats',
    key: 'stats',
    icon: <BarChartOutlined style={iconStyle} />,
    children: [
      {
        label: 'Success Stats',
        path: '/stats/success',
        component: 'StatsSuccess'
      },
      {
        label: 'User Stats',
        path: '/stats/user',
        component: 'StatsUser'
      },
      {
        label: 'Service Stats',
        path: '/stats/service',
        component: 'StatsService'
      },
      {
        label: 'Payment Stats',
        path: '/stats/payment',
        component: 'StatsPayment'
      },
      {
        label: 'Referral Stats',
        path: '/stats/referral',
        component: 'StatsReferral'
      },
      {
        label: 'All Stats',
        path: '/stats/daily',
        component: 'StatsDaily'
      },
    ]
  },
  {
    label: 'Add Data',
    path: '/add-data',
    key: 'add-data',
    icon: <AppstoreAddOutlined style={iconStyle} />,
    children: [
      {
        label: 'Add Provider',
        path: '/add-data/add-provider',
        component: 'AddProvider'
      },
      {
        label: 'Add Service',
        path: '/add-data/add-service',
        component: 'AddService'
      },
      {
        label: 'Add Gateway',
        path: '/add-data/add-gateway',
        component: 'AddGateway'
      },
      {
        label: 'Add Setting',
        path: '/add-data/add-setting',
        component: 'AddSetting'
      },
      {
        label: 'Add Provider Service',
        path: '/add-data/add-provider-service',
        component: 'AddProviderService'
      },
      // {
      //   label: 'Add API User',
      //   path: '/add-data/add-api-user',
      //   component: 'Providers'
      // },
      {
        label: 'Add Provider Service JSON',
        path: '/add-data/add-provider-service-json',
        component: 'AddProviderServiceJson'
      },
    ]
  },
  {
    label: 'Settings',
    path: '/settings',
    key: 'settings',
    icon: <SettingOutlined style={iconStyle} />,
    children: [
      {
        label: 'All Settings',
        path: '/settings/all',
        component: 'Settings'
      },
      // {
      //   label: 'Custom User API ID',
      //   path: '/settings/providers',
      //   component: 'Providers'
      // },
      // {
      //   label: 'Calculate User Payment Stats',
      //   path: '/settings/providers',
      //   component: 'Providers'
      // },
    ]
  },
  {
    label: 'Notifications',
    path: '/notifications',
    key: 'notifications',
    icon: <MessageOutlined style={iconStyle} />,
    children: [
      {
        label: 'Send Notification',
        path: '/notifications/send',
        component: 'SendNotification'
      }
    ]
  },
]

export const statusColors = {
  CREATED: '#1677ff',
  PENDING: '#faad14',
  REJECTED: '#ff5500',
  APPROVED: '#87d068',
  AWAITING: '#eb2e95',
  AWAITING_CLIENT_CONFIRMATION: '#eb2e95',
  MISMATCHED: '#4bc0c0',
  FAILED: '#F5212D'
}

export const hexToRGBA = (hex, opacity) => {
  // Remove the hash at the start if it's there
  hex = hex.replace('#', '');

  // Parse the hex color to get the Red, Green, and Blue components
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA color with the specified opacity
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}

export const statusOptions = [
  'ALL',
  'CREATED',
  'PENDING',
  'MISMATCHED',
  'FAILED',
  'REJECTED',
  'AWAITING CONFIRMATION',
  'APPROVED'
];

export const techsUI = [
  {
    key: '1',
    value: 'ReactJS',
    label: 'ReactJS',
  },
  {
    key: '2',
    value: 'Angular',
    label: 'Angular / Angular JS',
  },
  {
    key: '3',
    value: 'VueJS',
    label: 'VueJS',
  },
  {
    key: '4',
    value: 'HTML_CSS',
    label: 'HTML / CSS',
  },
  {
    key: '5',
    value: 'Other',
    label: 'Other',
  }
]

export const industries = [
  {
    key: '1',
    label: 'Retail',
    description: 'Covers businesses that sell products directly to consumers, either online or through physical stores.'
  },
  {
    key: '2',
    label: 'E-commerce',
    description: 'Specifically for businesses that operate online stores.'
  },
  {
    key: '3',
    label: 'Hospitality',
    description: 'Includes hotels, restaurants, bars, and other businesses that provide lodging, food, or beverages.'
  },
  {
    key: '4',
    label: 'Real Estate',
    description: 'Includes real estate agencies, property management firms, and other businesses related to buying, selling, or renting properties.'
  },
  {
    key: '5',
    label: 'Healthcare',
    description: 'Covers medical practices, clinics, hospitals, pharmacies, and other healthcare-related businesses.'
  },
  {
    key: '6',
    label: 'Education',
    description: 'Includes schools, colleges, universities, and other educational institutions.'
  },
  {
    key: '7',
    label: 'Transportation',
    description: 'Covers airlines, railways, shipping companies, logistics firms, and other businesses involved in transportation services.'
  },
  {
    key: '8',
    label: 'Finance',
    description: 'Covers banks, credit unions, financial institutions, and other businesses involved in financial services.'
  },
  {
    key: '9',
    label: 'Manufacturing',
    description: 'Includes factories and businesses involved in producing goods.'
  },
  {
    key: '10',
    label: 'Technology',
    description: 'Specifically for businesses that operate online stores.'
  },
  {
    key: '11',
    label: 'Entertainment',
    description: 'Covers software companies, hardware manufacturers, IT services providers, and other technology-related businesses.'
  },
  {
    key: '12',
    label: 'Consulting',
    description: 'Covers consulting firms and professional services providers.'
  },
  {
    key: '13',
    label: 'Legal',
    description: 'Includes law firms and legal services providers.'
  },
  {
    key: '14',
    label: 'Non-profit',
    description: 'Covers non-profit organizations and charities.'
  },
  {
    key: '15',
    label: 'Other',
    description: 'Provides an option for industries not covered by the predefined categories.'
  }
]