import React, { useState, useContext } from 'react';
import { LockOutlined, UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Form, Input, App } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { urlEndpoints } from '../helpers/constants';
import { AuthContext } from '../contexts/AuthContext';

const LoginForm = () => {
  const { notification } = App.useApp();
  // const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    
        try {
            setIsLoading(true);
            const resp = await axios.post(urlEndpoints.login, values);

            if (resp.status === 200) {
              // login(resp.data.token);
                await localStorage.setItem('token', resp.data.token);
                notification.info({ message: 'Logged in successfully!' });
                navigate('/dashboard');
            }
        } catch(e) {
            setIsLoading(false);
            notification.error({
              message: e.response.data || e.message || 'Something went wrong!'
            })
        } finally {
            setIsLoading(false);
        }
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      {/* <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="#h">
          Forgot password
        </a>
      </Form.Item> */}

      <Form.Item>
        <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
        {/* Or <a href="">register now!</a> */}
      </Form.Item>
    </Form>
  );
};
export default LoginForm;