import { Link, useNavigate, useRouteError } from "react-router-dom";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { useEffect } from "react";

function ErrorPage() {
    const navigate = useNavigate();
    let message = 'Could not find this page!';

    const error = useRouteError();

    useEffect(() => {
        const checkError = async () => {
            if (error.status === 404) {
                message = 'Page nahi na mila';
                console.log('Error : ', error);
                const isAuthenticated = await localStorage.getItem('token');
                if (!isAuthenticated) {
                    navigate("/auth");
                }
            }
        }
        checkError();
    }, []);
    

    return (
        <>
            <AuthenticatedLayout>
                <h1>An error occurred!</h1>
                <p>{message}</p>
                <Link to='/'>Go to Dashboard</Link>
            </AuthenticatedLayout>
        </>
    )
}

export default ErrorPage;