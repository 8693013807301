import React from 'react';
import bgImg from '../main-bg.jpg';

import LoginForm from '../components/LoginForm';
import { Navigate } from 'react-router-dom';


function Auth() {
    const isAuthenticated = localStorage.getItem('token');    

    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${bgImg})`, minHeight: '100vh', backgroundSize: 'cover'}}>
            <div style={{width: '350px', 
            backgroundColor: 'white', 
            padding: '2rem', borderRadius: '1rem', opacity: '1', 
            margin: '0.5rem'
            }}>
                <h1>Login</h1>
                <LoginForm />
            </div>
        </div>
    )
}

export default Auth;