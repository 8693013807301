import React, { Suspense, useEffect, useState, useContext, lazy } from 'react';
import { menuOptions, urlEndpoints } from './helpers/constants';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './components/Root';
import ErrorPage from './components/ErrorPage';
import axios from 'axios';
import './App.css';
// import Dashboard from './pages/Dashboard';
// import AddNewClient from './pages/AddNewClient';
// import ClientsGroup from './pages/ClientsGroup';
import Auth from './pages/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './components/Logout';
// import Transactions from './pages/Transactions';
// import BankAccounts from './pages/BankAccounts';
// import PaymentMethods from './pages/PaymentMethods';
import { App as AntdApp } from 'antd';
import { MenuProvider, MenuContext } from './contexts/MenuContext';
const Users = React.lazy(() => import('./pages/Users'));
const Services = React.lazy(() => import('./pages/Services'));
const Providers = React.lazy(() => import('./pages/Providers'));
const Gateways = React.lazy(() => import('./pages/Gateways'));
const AddProvider = React.lazy(() => import('./pages/AddProvider'));
const AddService = React.lazy(() => import('./pages/AddService'));
// const ClientsGroup = React.lazy(() => import('./pages/ClientsGroup'));
// const BankAccounts = React.lazy(() => import('./pages/BankAccounts'));
// const Transactions = React.lazy(() => import('./pages/Transactions'));
// const PaymentMethods = React.lazy(() => import('./pages/PaymentMethods'));
const AddBalance = React.lazy(() => import('./pages/AddBalance'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const StatsSuccess = React.lazy(() => import('./pages/StatsSuccess'));
const StatsUser = React.lazy(() => import('./pages/StatsUser'));
const StatsService = React.lazy(() => import('./pages/StatsService'));
const StatsPayment = React.lazy(() => import('./pages/StatsPayment'));
const StatsReferral = React.lazy(() => import('./pages/StatsReferral'));
const StatsDaily = React.lazy(() => import('./pages/StatsDaily'));
const SMS = React.lazy(() => import('./pages/SMS'));
const ContentReport = React.lazy(() => import('./pages/ContentReport'));
const BankTransactions = React.lazy(() => import('./pages/BankTransactions'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Payments = React.lazy(() => import('./pages/Payments'));
const ProviderServiceReport = React.lazy(() => import('./pages/ProviderServiceReport'));
const RequestReport = React.lazy(() => import('./pages/RequestReport'));
const AddGateway = React.lazy(() => import('./pages/AddGateway'));
const AddSetting = React.lazy(() => import('./pages/AddSetting'));
const EditProvider = React.lazy(() => import('./pages/EditProvider'));
const AddProviderService = React.lazy(() => import('./pages/AddProviderService'));
const AddProviderServiceJson = React.lazy(() => import('./pages/AddProviderServiceJson'));
const SendNotification = React.lazy(() => import('./pages/sendNotifications'));




const routerOptions = [];

const componentMapping = {
  default: ({ label }) => <h1>{label}</h1>,
  Users: () => <Suspense fallback={<div>Loading Users...</div>}>
    <Users />
  </Suspense>,
  Services: () => <Suspense fallback={<div>Loading Services...</div>}>
    <Services />
  </Suspense>,
  Providers: () => <Suspense fallback={<div>Loading Provider List...</div>}>
    <Providers />
  </Suspense>,
  Gateways: () => <Suspense fallback={<div>Loading Gateway List...</div>}>
    <Gateways />
  </Suspense>,
  AddProvider: () => <Suspense fallback={<div>Add Provider...</div>}>
    <AddProvider />
  </Suspense>,
  AddService: () => <Suspense fallback={<div>Loading Bank Accounts...</div>}>
    <AddService />
  </Suspense>,
  StatsSuccess: () => <Suspense fallback={<div>Loading Success Stats...</div>}>
    <StatsSuccess />
  </Suspense>,
  StatsUser: () => <Suspense fallback={<div>Loading User Stats...</div>}>
    <StatsUser />
  </Suspense>,
  StatsService: () => <Suspense fallback={<div>Loading Service Stats...</div>}>
    <StatsService />
  </Suspense>,
  StatsPayment: () => <Suspense fallback={<div>Loading Payment Stats...</div>}>
    <StatsPayment />
  </Suspense>,
  StatsReferral: () => <Suspense fallback={<div>Loading Referral Stats...</div>}>
    <StatsReferral />
  </Suspense>,
  StatsDaily: () => <Suspense fallback={<div>Loading Daily Stats...</div>}>
    <StatsDaily />
  </Suspense>,
  SMS: () => <Suspense fallback={<div>Loading SMS Report...</div>}>
    <SMS />
  </Suspense>,
  ContentReport: () => <Suspense fallback={<div>Loading Content Report Report...</div>}>
    <ContentReport />
  </Suspense>,
  BankTransactions: () => <Suspense fallback={<div>Loading Bank Transaction Report...</div>}>
    <BankTransactions />
  </Suspense>,
  Settings: () => <Suspense fallback={<div>Loading Settings page...</div>}>
    <Settings />
  </Suspense>,
  Payments: () => <Suspense fallback={<div>Loading Payments Report...</div>}>
    <Payments />
  </Suspense>,
  ProviderServiceReport: () => <Suspense fallback={<div>Loading Provider Service Report...</div>}>
    <ProviderServiceReport />
  </Suspense>,
  RequestReport: () => <Suspense fallback={<div>Loading Request Report...</div>}>
    <RequestReport />
  </Suspense>,
  AddGateway: () => <Suspense fallback={<div>Loading Add Gateway Page...</div>}>
    <AddGateway />
  </Suspense>,
  AddSetting: () => <Suspense fallback={<div>Loading Add Setting Page...</div>}>
    <AddSetting />
  </Suspense>,
  AddBalance: () => <Suspense fallback={<div>Loading Add Balance Page...</div>}></Suspense>,
  EditProvider: () => (
    <Suspense fallback={<div>Loading Edit Provider Page...</div>}>
      <EditProvider />
    </Suspense>
  ),

  AddProviderService: () => (
    <Suspense fallback={<div>Loading Add Provider Service Page...</div>}>
      <AddProviderService />
    </Suspense>
  ),
  AddProviderServiceJson: () => (
    <Suspense fallback={<div>Loading Add Provider Service Json Page...</div>}>
      <AddProviderServiceJson />
    </Suspense>
  ),
  Dashboard: () => (
    <Suspense fallback={<div>Loading Dashboard...</div>}>
      <Dashboard />
    </Suspense>
  ),
  SendNotification: () => (
    <Suspense fallback={<div>Loading Send Notification Page...</div>}>
      <SendNotification />
    </Suspense>
  ),

}

const getPaymentMethodsList = async (token) => {
  try {
    // setIsLoading(true);
    // const token = await localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    const resp = await axios.get(`${urlEndpoints.getProviderServiceReport}`, { headers });
    if (resp.status === 200) {
      const { paymentModes, message } = resp.data;
      // notification.success({
      //   // message: 'Success',
      //   description: message || 'Fetched Payment Methods Successfully!'
      // })
      return paymentModes;
      // setPaymentMethods(paymentModes);
    } else {
      return [];
    }

  } catch (e) {
    console.log('Error : ', e);
    return [];
    // notification.error({
    //     // message: 'Error',
    //     description: e.response.data || 'Something went wrong!'
    // })
  }
  // finally {
  //     // setIsLoading(false);
  // }
}



const AppComponent = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  // const { menuOptions, updateMenuOptions } = useContext(MenuContext);

  menuOptions.forEach(item => {
    if (item.children) {
      item.children.forEach(childItem => {
        const Component = componentMapping[childItem.component] || componentMapping.default;
        const { key, ...props } = childItem;
        routerOptions.push({ path: childItem.path, key: item.path, element: <ProtectedRoute><Component {...props} /></ProtectedRoute> });
      });
      routerOptions.push({ path: '/add-data/add-balance', key: '/add-data/add-balance', element: <ProtectedRoute><AddBalance /></ProtectedRoute> });
    }
    const Component = componentMapping[item.component] || componentMapping.default;
    const { key, ...props } = item;
    routerOptions.push({ path: item.path, key: item.path, element: <ProtectedRoute key={key}><Component {...props} /></ProtectedRoute> });
  })


  // routerOptions.push({ path: '/dashboard', key: "dashboard", element: <ProtectedRoute key={'dashboard'}><Dashboard /></ProtectedRoute> })
  window.routerOptions = routerOptions;


  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: routerOptions
    },
    {
      path: '/auth',
      element: <Auth />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: "reports/provider-service/:serviceId",
      element: <ProviderServiceReport />
    },
    {
      path: "stats/service/:serviceId",
      element: <StatsService />
    },
    {
      path: "edit-provider/:id",
      element: (
        <Suspense fallback={<div>Loading Edit Provider Page...</div>}>
          <EditProvider />
        </Suspense>
      )

    }
  ]);

  useEffect(() => {
    const getPaymentMethods = async () => {
      const token = await localStorage.getItem('token');

      if (token) {
        const methods = await getPaymentMethodsList(token);
        setPaymentMethods(methods);

      } else {
        setPaymentMethods([]);
      }
    };
    getPaymentMethods();
  }, [localStorage.getItem]);

  return <AntdApp><RouterProvider router={router} /></AntdApp>;
};

export default AppComponent;
